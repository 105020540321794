<script setup lang="ts">
import UnavailableCountries from "@/components/UnavailableCountries.vue";
import { onMounted, ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import Amplitude from "@/helpers/amplitude";
import { getQueryData } from "@/helpers/query";
import { onEvent } from "@/helpers/events";
import { useLayoutStore } from "@/store/layout";
import config from "@/config/env";

const route = useRoute();
const router = useRouter();
const i18n = useI18n();
const { localeCodes } = useI18n();
const layoutStore = useLayoutStore();
const isReady = ref<boolean>(false);

const i18nHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: undefined,
  addSeoAttributes: {},
});

const localeDirection = computed<"ltr" | "rtl">(() => {
  return i18nHead.value.htmlAttrs.dir || "ltr";
});

useHead({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs.lang!,
    class: "default",
    style: "",
  },
  bodyAttrs: {
    style: "",
  },
  link: [
    ...(i18nHead.value.link.filter(
      (item) => item.hreflang && item.hreflang.includes("-"),
    ) || []),
    { rel: "canonical", href: () => `${config.appUrl}${route.path}` },
  ],
  meta: [...(i18nHead.value.meta || [])],
});
const contentEl = ref<HTMLElement | null>(null);

const promocodeTrafficReq = async () => {
  try {
    const params = new URLSearchParams(window?.location?.search || "");
    const body = {
      code: window?.localStorage.getItem("hash"),
      utm_source: params.get("utm_source") || "",
      utm_medium: params.get("utm_medium") || "",
      utm_campaign: params.get("utm_campaign") || "",
      utm_content: params.get("utm_content") || "",
      utm_term: params.get("utm_term") || "",
    };
    await $fetch(`/api/v1/promocode/traffic-count`, {
      method: "POST",
      body,
    });
  } catch (x) {
    console.error(x);
  }
};
// A/B Testing
onBeforeMount(() => {
  document.documentElement.setAttribute("dir", localeDirection.value);
  const landingExperiment =
    window.landingExperiment || route.query["landing-version"];
  if (landingExperiment !== undefined && landingExperiment !== 0) {
    layoutStore.landingVersion = String(landingExperiment);
  }
});
// A/B Testing
onMounted(() => {
  if (route.path !== "/") {
    const lang = route.query.lang;
    if (lang && localeCodes.value && localeCodes.value.includes(String(lang))) {
      i18n.setLocale(String(lang));
    }
  }

  const params = new URLSearchParams(window?.location?.search || "");
  const hash = params.get("hash");
  if (hash) {
    const validHash = hash.replace(/[^a-z0-9]/gi, "");
    window.localStorage.setItem("hash", validHash);
    promocodeTrafficReq();
  }
  const utmSource = params.get("utm_source");

  if (hash) {
    Amplitude.Instance.setUserProperties({
      source: "referral_user",
      referral_user_hash: hash,
    });
  } else if (utmSource) {
    Amplitude.Instance.setUserProperties({
      source: utmSource,
    });
  } else {
    Amplitude.Instance.setUserProperties({
      source: document?.referrer || "",
    });
  }

  // customer_io
  if (utmSource === "c_io") {
    onEvent(
      "customer_io",
      {},
      {
        utm_source: params.get("utm_source") || "",
        utm_medium: params.get("utm_medium") || "",
        utm_campaign: params.get("utm_campaign") || "",
        utm_content: params.get("utm_content") || "",
        utm_term: params.get("utm_term") || "",
      },
    );
  }
  Amplitude.setUserPropertyFromQuery(route);
  getQueryData(route, router);

  // A/B Testing

  if (layoutStore.landingVersion && i18n.locale) {
    Amplitude.Instance.setUserProperties({
      landing: layoutStore.landingVersion,
      landing_locale: i18n.locale.value,
    });
  }

  const root = document.documentElement;
  root.classList.add(`lv-${layoutStore.landingVersion}`);
  // A/B Testing
});

watch(localeDirection, () => {
  document.documentElement.setAttribute("dir", localeDirection.value);
});

const isShowUnavailableCountries = computed(() => {
  const route = useRoute();
  return isReady.value && route.path !== "/private-landing";
});

onNuxtReady(() => {
  isReady.value = true;
});

useSeoMeta({
  ogImage: config.appUrl + "/og-image.png",
  ogType: "website",
});
</script>

<template>
  <div>
    <UnavailableCountries v-if="isShowUnavailableCountries" />
    <slot />
  </div>
</template>
